import '@mui/lab/themeAugmentation';
import { Breakpoint, CSSObject, Components, Theme, alpha, darken, inputBaseClasses, lighten, selectClasses } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { ComponentsProps } from '@mui/material/styles/props';
import { defaultBreakPoints } from './defaultBreakPoints';

export const defaultComponents: Partial<Components<Theme>> = {
    MuiAppBar: {
        styleOverrides: {
            root: ({ theme }) => ({
                zIndex: theme.zIndex.drawer + 1,
                background: theme.palette.common.white,
                borderTopWidth: `${theme.settings.appBar.borderTop}px`,
                borderTopStyle: 'solid',
                borderTopColor: theme.palette.secondary.main,
                color: 'inherit',
            }),
        },
    },

    MuiAlertTitle: {
        styleOverrides: {
            root: ({ theme }) => ({
                fontFamily: theme.typography.alternate.fontFamily,
                fontWeight: 300,
            }),
        },
    },
    MuiBackdrop: {
        styleOverrides: {
            root: ({ theme }) => ({
                backgroundColor: alpha(darken(theme.palette.charcoal.main, 0.4), 0.2),
                backdropFilter: 'blur(2px)',

                '&.MuiBackdrop-invisible': {
                    backgroundColor: 'transparent',
                    backdropFilter: 'blur(2px)',
                },
            }),
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                marginLeft: 8,
                marginRight: 8,
            },
        },
    },
    MuiCssBaseline: {
        styleOverrides: (theme) => ({
            'html, body': {
                width: '100%',
                height: '100%',
            },
            body: {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
                width: '100%',
                flex: 1,
            },
            '#root': {
                width: '100%',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
            },
            html: {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
                width: '100%',
                MozOsxFontSmoothing: 'grayscale',
                WebkitFontSmoothing: 'antialiased',
            },
            '.child-popover .MuiPaper-root .MuiList-root': {
                flexDirection: 'column',
            },
            '#nprogress': {
                pointerEvents: 'none',
            },
            '#nprogress .bar': {
                background: lighten(theme.palette.primary.main, 0.85),
            },
            '#nprogress .spinner-icon': {
                borderTopColor: lighten(theme.palette.primary.main, 0.85),
                borderLeftColor: lighten(theme.palette.primary.main, 0.85),
            },
            '#nprogress .peg': {
                boxShadow: '0 0 15px ' + lighten(theme.palette.primary.main, 0.85) + ', 0 0 8px' + theme.palette.primary.light,
            },
            ':root': {
                '--swiper-theme-color': theme.palette.primary.main,
            },
            code: {
                background: lighten(theme.palette.info.main, 0.85),
                color: theme.palette.info.dark,
                borderRadius: 4,
                padding: 4,
            },
            '@keyframes pulse': {
                '0%': {
                    transform: 'scale(.75)',
                },
                '20%': {
                    transform: 'scale(1.1)',
                },
                '40%': {
                    transform: 'scale(.75)',
                },
                '60%': {
                    transform: 'scale(1.05)',
                },
                '80%': {
                    transform: 'scale(.75)',
                },
                '100%': {
                    transform: 'scale(.75)',
                },
            },
            '@keyframes ripple': {
                '0%': {
                    transform: 'scale(.8)',
                    opacity: 1,
                },
                '100%': {
                    transform: 'scale(2.8)',
                    opacity: 0,
                },
            },
            '@keyframes float': {
                '0%': {
                    transform: 'translate(0%, 0%)',
                },
                '100%': {
                    transform: 'translate(3%, 3%)',
                },
            },
        }),
    },
    MuiSelect: {
        styleOverrides: {
            root: ({ theme }) => ({
                [`& .${selectClasses.icon}.${selectClasses.outlined}`]: {
                    color: theme.colors.alpha.black[50],
                },
            }),
            icon: {
                top: 'calc(50% - 14px)',
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: ({ theme }) => ({
                [`& .${outlinedInputClasses.notchedOutline}`]: {
                    transition: theme.transitions.create(['background-color', 'border-color'], {
                        duration: theme.transitions.duration.short,
                    }),
                },
                [`:not(.${inputBaseClasses.focused}):not(.${inputBaseClasses.error}):hover .${outlinedInputClasses.notchedOutline}`]: {
                    borderColor: theme.settings.general.outlinedInputBorderColorHover,
                },
            }),
            input: ({ theme }) => ({
                overflow: 'hidden',
                backgroundColor: theme.colors.alpha.white[100],
            }),
            notchedOutline: ({ theme }) => ({
                borderColor: theme.settings.general.outlinedInputBorderColor,
            }),
        },
    },
    MuiListSubheader: {
        styleOverrides: {
            colorPrimary: ({ theme }) => ({
                fontWeight: 'bold',
                lineHeight: '40px',
                fontSize: 13,
                background: theme.colors.alpha.black[5],
                color: theme.colors.alpha.black[70],
            }),
        },
    },
    MuiCardHeader: {
        styleOverrides: {
            action: {
                marginTop: -5,
                marginBottom: -5,
            },
            title: {
                fontSize: 15,
            },
        },
    },
    MuiRadio: {
        styleOverrides: {
            root: {
                borderRadius: '50px',
            },
        },
    },
    // MuiChip: {
    //     styleOverrides: {
    //         colorSecondary: ({ theme }) => ({
    //             background: theme.colors.alpha.black[5],
    //             color: theme.colors.alpha.black[100],
    //             '&:hover': {
    //                 background: theme.colors.alpha.black[10],
    //             },
    //         }),
    //         deleteIcon: ({ theme }) => ({
    //             color: theme.palette.error.light,
    //             '&:hover': {
    //                 color: theme.palette.error.main,
    //             },
    //         }),
    //     },
    // },
    MuiAccordion: {
        styleOverrides: {
            root: {
                boxShadow: 'none',

                '&.Mui-expanded': {
                    margin: 0,
                },
                '&::before': {
                    display: 'none',
                },
            },
        },
    },
    MuiAvatar: {
        styleOverrides: {
            root: {
                fontSize: 14,
                fontWeight: 'bold',
            },
            colorDefault: ({ theme }) => ({
                background: theme.colors.alpha.black[30],
                color: theme.colors.alpha.white[100],
            }),
        },
    },
    MuiAvatarGroup: {
        styleOverrides: {
            root: {
                alignItems: 'center',
            },
            avatar: ({ theme }) => ({
                background: theme.colors.alpha.black[10],
                fontSize: 13,
                color: theme.colors.alpha.black[70],
                fontWeight: 'bold',

                '&:first-of-type': {
                    border: 0,
                    background: 'transparent',
                },
            }),
        },
    },
    MuiListItemAvatar: {
        styleOverrides: {
            alignItemsFlexStart: {
                marginTop: 0,
            },
        },
    },
    MuiPaginationItem: {
        styleOverrides: {
            page: {
                fontSize: 13,
                fontWeight: 'bold',
                transition: 'all .2s',
            },
            textPrimary: ({ theme }) => ({
                '&.Mui-selected': {
                    boxShadow: theme.colors.shadows.primary,
                },
                '&.MuiButtonBase-root:hover': {
                    background: theme.colors.alpha.black[5],
                },
                '&.Mui-selected.MuiButtonBase-root:hover': {
                    background: theme.palette.primary.main,
                },
            }),
        },
    },
    MuiButton: {
        styleOverrides: {
            root: ({ theme }) => ({
                textTransform: 'none',
                fontWeight: 'normal',
                padding: theme.spacing(0.44, 4),
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
                '.MuiSvgIcon-root': {
                    transition: 'all .2s',
                },
                variants: [
                    {
                        props: ({ ownerState }) => ownerState.variant !== 'text',
                        style: { whiteSpace: 'nowrap' },
                    },
                    {
                        props: {
                            variant: 'text',
                        },
                        style: {
                            verticalAlign: 'baseline',
                            fontSize: 'inherit',
                        },
                    },
                    {
                        props: {
                            variant: 'text',
                            color: 'light',
                        },
                        style: {
                            backgroundColor: theme.palette.common.white,
                            color: theme.palette.light.contrastText,
                            '&:hover': {
                                backgroundColor: theme.palette.light.main,
                            },
                        },
                    },
                    {
                        props: {
                            variant: 'outlined',
                            color: 'light',
                        },
                        style: {
                            borderColor: theme.palette.primary.main,
                            backgroundColor: theme.palette.light.light,
                            color: theme.palette.light.contrastText,
                            '&:hover': {
                                borderColor: theme.palette.primary.main,
                                backgroundColor: theme.palette.light.main,
                            },
                        },
                    },
                    {
                        props: {
                            variant: 'contained',
                            color: 'primary',
                        },
                        style: {
                            '&:hover': {
                                color: theme.palette.primary.contrastText,
                            },
                        },
                    },
                    {
                        props: {
                            variant: 'contained',
                            color: 'primary',
                        },
                        style: {
                            '&:hover': {
                                color: theme.palette.primary.contrastText,
                            },
                        },
                    },
                    {
                        props: {
                            variant: 'contained',
                            color: 'success',
                        },
                        style: {
                            color: theme.palette.primary.contrastText,
                            '&:hover': {
                                color: theme.palette.primary.contrastText,
                            },
                        },
                    },
                    {
                        props: ({ ownerState }) => !ownerState.size || ownerState.size === 'medium',
                        style: {
                            padding: theme.spacing(0.4375, 4, 0.4375, 4),
                        },
                    },
                    {
                        props: {
                            size: 'extra-large',
                        },
                        style: {
                            fontSize: 18,
                            fontWeight: 700,
                            padding: theme.spacing(1.75, 6),
                        },
                    },
                ],
            }),
            //         endIcon: {
            //             marginRight: -8,
            //         },
            //         containedSecondary: ({ theme }) => ({
            //             backgroundColor: theme.palette.secondary.main,
            //             color: theme.colors.alpha.white[100],
            //             border: '1px solid ' + theme.colors.alpha.black[30],
            //         }),
            //         outlinedSecondary: ({ theme }) => ({
            //             backgroundColor: theme.colors.alpha.white[100],
            //
            //             '&:hover, &.MuiSelected': {
            //                 backgroundColor: theme.colors.alpha.black[5],
            //                 color: theme.colors.alpha.black[100],
            //             },
            //         }),
            //         sizeSmall: {
            //             padding: '6px 16px',
            //             lineHeight: 1.5,
            //         },
            //         sizeMedium: {
            //             padding: '8px 20px',
            //         },
            //         sizeLarge: {
            //             padding: '11px 24px',
            //         },
            //         textSizeSmall: {
            //             padding: '7px 12px',
            //         },
            //         textSizeMedium: {
            //             padding: '9px 16px',
            //         },
            //         textSizeLarge: {
            //             padding: '12px 16px',
            //         },
            //     },
            // },
            // MuiButtonBase: {
            //     defaultProps: {
            //         disableRipple: false,
            //     },
            //     styleOverrides: {
            //         root: {
            //             borderRadius: 6,
            //         },
            //     },
            // },
            // MuiIconButton: {
            //     styleOverrides: {
            //         root: {
            //             borderRadius: 8,
            //             padding: 8,
            //
            //             '& .MuiTouchRipple-root': {
            //                 borderRadius: 8,
            //             },
            //         },
            //         sizeSmall: {
            //             padding: 4,
            //         },
        },
    },
    MuiListItemText: {
        styleOverrides: {
            root: {
                margin: 0,
            },
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                '& .MuiTouchRipple-root': {
                    opacity: 0.3,
                },
            },
        },
    },
    MuiDivider: {
        styleOverrides: {
            root: ({ theme }) => ({
                background: theme.colors.alpha.black[10],
                border: 0,
                height: 1,
            }),
            vertical: {
                height: 'auto',
                width: 1,
                '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
                    height: 'auto',
                },
                '&.MuiDivider-absolute.MuiDivider-fullWidth': {
                    height: '100%',
                },
            },
            withChildren: {
                '&:before, &:after': {
                    border: 0,
                },
            },
            wrapper: ({ theme }) => ({
                background: theme.colors.alpha.white[100],
                fontWeight: 'bold',
                height: 24,
                lineHeight: '24px',
                marginTop: -12,
                color: 'inherit',
                textTransform: 'uppercase',
            }),
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                padding: 0,
            },
            elevation0: {
                boxShadow: 'none',
            },
        },
    },
    MuiLink: {
        defaultProps: {
            underline: 'hover',
        } as ComponentsProps['MuiLink'],
    },
    MuiLinearProgress: {
        styleOverrides: {
            root: {
                borderRadius: 6,
                height: 6,
            },
        },
    },
    MuiSlider: {
        styleOverrides: {
            root: ({ theme }) => ({
                '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
                    transform: 'none',
                },
                '& .MuiSlider-valueLabel': {
                    borderRadius: 6,
                    background: theme.colors.alpha.black[100],
                    color: theme.colors.alpha.white[100],
                },
            }),
        },
    },
    MuiList: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: 0,
                '& .MuiListItem-button': {
                    transition: 'all .2s',
                    '& > .MuiSvgIcon-root': {
                        minWidth: 34,
                    },
                    '& .MuiTouchRipple-root': {
                        opacity: 0.2,
                    },
                },
                '& .MuiListItem-root.MuiButtonBase-root.Mui-selected': {
                    backgroundColor: alpha(lighten(theme.palette.primary.main, 0.85), 0.4),
                },
                '& .MuiMenuItem-root.MuiButtonBase-root:active': {
                    backgroundColor: alpha(lighten(theme.palette.primary.main, 0.85), 0.4),
                },
                '& .MuiMenuItem-root.MuiButtonBase-root .MuiTouchRipple-root': {
                    opacity: 0.2,
                },
            }),
            padding: {
                padding: '12px',
                '& .MuiListItem-button': {
                    borderRadius: 6,
                    margin: '1px 0',
                },
            },
        },
    },
    // MuiTabs: {
    //     styleOverrides: {
    //         root: {
    //             height: 38,
    //             minHeight: 38,
    //             overflow: 'visible',
    //         },
    //         indicator: ({ theme }) => ({
    //             height: 38,
    //             minHeight: 38,
    //             borderRadius: 6,
    //             border: '1px solid ' + theme.palette.primary.dark,
    //             boxShadow: '0px 2px 10px ' + theme.palette.primary.light,
    //         }),
    //         scrollableX: {
    //             overflow: 'visible !important',
    //         },
    //     },
    // },
    // MuiTab: {
    //     styleOverrides: {
    //         root: ({ theme }) => ({
    //             padding: 0,
    //             height: 38,
    //             minHeight: 38,
    //             borderRadius: 6,
    //             transition: 'color .2s',
    //             textTransform: 'capitalize',
    //
    //             '&.MuiButtonBase-root': {
    //                 minWidth: 'auto',
    //                 paddingLeft: 20,
    //                 paddingRight: 20,
    //                 marginRight: 4,
    //             },
    //             '&.Mui-selected, &.Mui-selected:hover': {
    //                 color: theme.colors.alpha.white[100],
    //                 zIndex: 5,
    //             },
    //             '&:hover': {
    //                 color: theme.colors.alpha.black[100],
    //             },
    //         }),
    //     },
    // },
    MuiMenu: {
        styleOverrides: {
            paper: {
                padding: 12,
            },
            list: ({ theme }) => ({
                padding: 12,
                '& .MuiMenuItem-root.MuiButtonBase-root': {
                    fontSize: 14,
                    marginTop: 1,
                    marginBottom: 1,
                    transition: 'all .2s',
                    color: theme.colors.alpha.black[70],
                    '& .MuiTouchRipple-root': {
                        opacity: 0.2,
                    },
                    '&:hover, &:active, &.active, &.Mui-selected': {
                        color: theme.colors.alpha.black[100],
                        background: alpha(lighten(theme.palette.primary.main, 0.85), 0.4),
                    },
                },
            }),
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: ({ theme }) => ({
                background: 'transparent',
                transition: 'all .2s',
                '&:hover, &:active, &.active, &.Mui-selected': {
                    color: theme.colors.alpha.black[100],
                    background: alpha(lighten(theme.palette.primary.main, 0.85), 0.4),
                },
                '&.Mui-selected:hover': {
                    background: alpha(lighten(theme.palette.primary.main, 0.85), 0.4),
                },
            }),
        },
    },
    MuiListItem: {
        styleOverrides: {
            root: ({ theme }) => ({
                '&.MuiButtonBase-root': {
                    color: theme.palette.secondary.main,
                    '&:hover, &:active, &.active, &.Mui-selected': {
                        color: theme.colors.alpha.black[100],
                        background: lighten(lighten(theme.palette.primary.main, 0.85), 0.5),
                    },
                },
            }),
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            tag: {
                margin: 1,
            },
            root: {
                '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                    right: 14,
                },
            },
            clearIndicator: ({ theme }) => ({
                background: lighten(theme.palette.error.main, 0.85),
                color: theme.palette.error.main,
                marginRight: 8,
                '&:hover': {
                    background: lighten(theme.palette.error.main, 0.85),
                    color: theme.palette.error.dark,
                },
            }),
            popupIndicator: ({ theme }) => ({
                color: theme.colors.alpha.black[50],
                '&:hover': {
                    background: lighten(theme.palette.primary.main, 0.85),
                    color: theme.palette.primary.main,
                },
            }),
        },
    },
    MuiTablePagination: {
        styleOverrides: {
            toolbar: {
                '& .MuiIconButton-root': {
                    padding: 8,
                },
            },
            select: {
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    MuiToolbar: {
        styleOverrides: {
            root: ({ theme }) => ({
                ...Object.entries(theme.settings.appBar.heights as Record<Breakpoint, number>).reduce(
                    (result, [beakPoint, height]) => {
                        result[`${defaultBreakPoints.up(beakPoint as Breakpoint)}`] = {
                            minHeight: height - (theme.settings.appBar.borderTop || 0),
                        };
                        return result;
                    },
                    {} as Record<string, CSSObject>
                ),
            }),
        },
    },
    MuiTableRow: {
        styleOverrides: {
            head: ({ theme }) => ({
                background: theme.colors.alpha.black[5],
            }),
            root: ({ theme }) => ({
                transition: 'background-color .2s',
                '&.MuiTableRow-hover:hover': {
                    backgroundColor: theme.colors.alpha.black[5],
                },
            }),
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: ({ theme }) => ({
                borderBottomColor: theme.colors.alpha.black[10],
                fontSize: 14,
            }),
            head: ({ theme }) => ({
                textTransform: 'uppercase',
                fontSize: 13,
                fontWeight: 'bold',
                color: theme.colors.alpha.black[70],
            }),
        },
    },
    MuiAlert: {
        styleOverrides: {
            message: {
                lineHeight: 1.5,
                fontSize: 14,
            },
            standardInfo: ({ theme }) => ({
                color: theme.palette.info.main,
            }),
            action: ({ theme }) => ({
                color: theme.colors.alpha.black[70],
            }),
        },
    },
    MuiTimelineDot: {
        styleOverrides: {
            root: {
                margin: 0,
                zIndex: 5,
                position: 'absolute',
                top: '50%',
                marginTop: -6,
                left: -6,
            },
            outlined: ({ theme }) => ({
                backgroundColor: theme.colors.alpha.white[100],
                boxShadow: '0 0 0 6px ' + theme.colors.alpha.white[100],
            }),
            outlinedPrimary: ({ theme }) => ({
                backgroundColor: theme.colors.alpha.white[100],
                boxShadow: '0 0 0 6px ' + theme.colors.alpha.white[100],
            }),
        },
    },
    MuiTimelineConnector: {
        styleOverrides: {
            root: ({ theme }) => ({
                position: 'absolute',
                height: '100%',
                top: 0,
                borderRadius: 50,
                backgroundColor: theme.colors.alpha.black[10],
            }),
        },
    },
    MuiTimelineItem: {
        styleOverrides: {
            root: {
                minHeight: 0,
                padding: '8px 0',
                '&:before': {
                    display: 'none',
                },
            },
            missingOppositeContent: {
                '&:before': {
                    display: 'none',
                },
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: ({ theme }) => ({
                backgroundColor: alpha(theme.colors.alpha.black['50'], 0.85),
                padding: '8px 16px',
                fontSize: 13,
            }),
            arrow: ({ theme }) => ({
                color: alpha(theme.colors.alpha.black['50'], 0.85),
            }),
        },
    },
    MuiSwitch: {
        styleOverrides: {
            root: {
                height: 33,
                overflow: 'visible',
                '& .MuiButtonBase-root': {
                    position: 'absolute',
                    padding: 6,
                    transition: 'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                },
                '& .MuiIconButton-root': {
                    borderRadius: 100,
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    opacity: 0.3,
                },
            },
            thumb: ({ theme }) => ({
                border: '1px solid ' + theme.colors.alpha.black[30],
                boxShadow: '0px 9px 14px ' + theme.colors.alpha.black[10] + ', 0px 2px 2px ' + theme.colors.alpha.black[10],
            }),
            track: ({ theme }) => ({
                backgroundColor: theme.colors.alpha.black[5],
                border: '1px solid ' + theme.colors.alpha.black[10],
                boxShadow: 'inset 0px 1px 1px ' + theme.colors.alpha.black[10],
                opacity: 1,
            }),
            colorPrimary: ({ theme }) => ({
                '& .MuiSwitch-thumb': {
                    backgroundColor: theme.colors.alpha.white[100],
                },
                '&.Mui-checked .MuiSwitch-thumb': {
                    backgroundColor: theme.palette.primary.main,
                },
            }),
        },
    },
    MuiStepper: {
        styleOverrides: {
            root: ({ theme }) => ({
                paddingTop: 20,
                paddingBottom: 20,
                background: theme.colors.alpha.black[5],
            }),
        },
    },
    MuiStepIcon: {
        styleOverrides: {
            root: ({ theme }) => ({
                '&.MuiStepIcon-completed': {
                    color: theme.palette.success.main,
                },
            }),
        },
    },
    MuiTypography: {
        defaultProps: {
            variantMapping: {
                h1: 'h1',
                h2: 'h2',
                h3: 'div',
                h4: 'div',
                h5: 'div',
                h6: 'div',
                subtitle1: 'div',
                subtitle2: 'div',
                body1: 'div',
                body2: 'div',
            },
        },
        styleOverrides: {
            gutterBottom: {
                marginBottom: 4,
            },
            paragraph: {
                fontSize: 17,
                lineHeight: 1.7,
            },
        },
    },
};
